<template>
    <a-spin :spinning="spinning">
        <ele-table ref="rt" :columns="columns" :table-data="data" :total-items="totalItems" :actions-type="actionsType"
            :is-full="true" :current-page="pageNumber" :hide-row-selection="true" @emitRefreshData="clearFilterAndRefresh"
            @emitSortData="updateSortData" @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange">
            <!-- 查询 -->
            <a-row :gutter="8">
                <a-col class="gutter-row" :span="3">
                    <a-input v-model.trim="SearchDto.name" placeholder="客户名称" allow-clear />
                </a-col>
                <a-col class="gutter-row" :span="3">
                    <a-input v-model.trim="SearchDto.projectName" placeholder="项目名称" allow-clear />
                </a-col>
                <a-col class="gutter-row" :span="2">
                    <a-select v-model="SearchDto.status" style="width: 100%" show-search :filter-option="filterOption"
                        :options="statusOptions" placeholder="开票状态" allowClear>
                    </a-select>
                </a-col>
                <a-col class="gutter-row" :span="2">
                    <a-select v-model="SearchDto.category" style="width: 100%" show-search :filter-option="filterOption"
                        :options="categoryOptions" placeholder="开票类别" allowClear>
                    </a-select>
                </a-col>
                <a-col class="gutter-row" :span="2">
                    <a-select v-model="SearchDto.invoiceType" style="width: 100%" show-search :filter-option="filterOption"
                        :options="typeOptions" placeholder="开票类型" allowClear>
                    </a-select>
                </a-col>
                <a-col class="gutter-row" :span="2">
                    <a-input v-model.trim="SearchDto.invoiceMonth" placeholder="账单月份" allow-clear />
                </a-col>
                <!-- <a-col class="gutter-row" :span="4">
                    <a-range-picker :placeholder="['开始时间', '结束时间']" v-model="startToEndDate" />
                </a-col> -->
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary" @click="getData()" v-if="isGranted('Pages.TPS_Invoice.Query')">
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
            </a-row>
        </ele-table>
    </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy, initFieldLibOptions } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import ListDetail from "./listDetail";
import ApprovalModal from "./approvalModal";

export default {
    mixins: [AppComponentBase],
    name: "tpsInvoice-index",
    components: { EleTable },
    data() {
        return {
            spinning: false,
            selectedRowKeys: [],
            SearchDto: {
                name: '',
                projectName: '',
                status: undefined,
                category: undefined,
                invoiceType: undefined,
                invoiceMonth: '',
                startDate: '',
                endDate: ''
            },
            startToEndDate: [],
            // 总记录数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 总页数
            totalPages: 1,
            // 
            pagerange: [1, 1],
            // 每页显示数
            pageSizeOptions: ["10", "20", "30", "40"],
            request: { sorting: "creationTime desc", maxResultCount: 50, skipCount: 0 },
            // 表头
            columns: [],
            actionsType: null,
            // 数据
            data: [],
            //状态类型
            statusOptions: [
                {
                    label: '待处理',
                    value: 1
                },
                {
                    label: '处理中',
                    value: 2
                },
                {
                    label: '已处理',
                    value: 3
                },
                {
                    label: '已驳回',
                    value: 4
                },
            ],
            //开票类别
            categoryOptions: [
                {
                    label: '专票',
                    value: 'ZHUAN'
                },
                {
                    label: '普票',
                    value: 'PU'
                },
            ],
            //开票类型
            typeOptions: [
                {
                    label: '公对公',
                    value: 1
                },
                {
                    label: '公对个体工商户',
                    value: 2
                },
            ]
        };
    },
    created() {
        this.getData();
        this.initActionsType();
        this.initColums();
    },
    methods: {
        /**
 * 搜索框
 * @param input
 * @param option
 */
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        initColums() {
            let _this = this;
            this.columns = [
                {
                    title: "客户名称",
                    dataIndex: "companyName",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "companyName" },
                },
                {
                    title: "项目名称",
                    dataIndex: "projectName",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "projectName" },
                },
                {
                    title: "账单月份",
                    dataIndex: "invoiceMonth",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "invoiceMonth" }
                },
                {
                    title: "申请时间",
                    dataIndex: "creationTime",
                    sorter: true,
                    align: "center",
                    type: "custom",
                    scopedSlots: { customRender: "creationTime" },
                    customRender: function (text, record, index) {
                        return moment(text).format("YYYY-MM-DD")
                    },

                },
                {
                    title: "发票类别",
                    dataIndex: "category",
                    sorter: false,
                    align: "center",
                    type: "custom",
                    scopedSlots: { customRender: "category" },
                    customRender: function (text, record, index) {
                        return text == "ZHUAN" ? '专票' : '普票';
                    },
                },
                {
                    title: "批次总笔数",
                    dataIndex: "invoiceDetailCount",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "invoiceDetailCount" }
                },
                {
                    title: "申请开票金额",
                    dataIndex: "totalAmount",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "totalAmount" }
                },
                {
                    title: "状态",
                    dataIndex: "status",
                    sorter: false,
                    align: "center",
                    type: "custom",
                    scopedSlots: { customRender: "status" },
                    customRender: function (text, record, index) {
                        if (text == 'Pending') {
                            return "待处理";
                        }
                        else if (text == 'Processing') {
                            return "处理中";
                        }
                        else if (text == 'Processed') {
                            return "已处理";
                        } else {
                            return "已驳回";
                        }
                    },
                },
                {
                    title: "请求备注",
                    dataIndex: "remark",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "remark" }
                },
                {
                    title: this.l("Action"),
                    dataIndex: "actions",
                    fixed: "right",
                    width: 200,
                    scopedSlots: { customRender: "actions" },
                }
            ];
        },
        initActionsType() {
            let _this = this;
            let obj = {
                type: "tpsInvoice",
                isShow: true,
                fns: {
                    detailList: [
                        {
                            granted: true,
                            name: '明细',
                            //icon: "profile",
                            fn: (data) => {
                                ModalHelper.create(
                                    ListDetail,
                                    { id: data.id, isReject: data.status != 'Reject' },
                                    {
                                        width: "1200px",
                                    }
                                ).subscribe((res) => {
                                    if (res) {
                                    this.getData();
                                    }
                                });
                            },
                        }, {
                            granted: this.isGranted("Pages.TPS_Invoice.Reject"),
                            showFunc(record) {
                                return !(record.status == 'Processed' || record.status == 'Reject');
                            },
                            name: '驳回',
                            //icon: "profile",
                            fn: (data) => {
                                ModalHelper.create(
                                    ApprovalModal,
                                    { id: data.id,type:1 },
                                    {
                                        width: "500px",
                                    }
                                ).subscribe((res) => {
                                    if (res) {
                                    this.getData();
                                    }
                                });

                            },
                        }
                    ],
                    isShow: true,
                },
            };
            this.actionsType = obj;
        },
        getData() {
            let data = {
                ...this.request, ...this.SearchDto
            };
            if (this.startToEndDate.length > 1) {
                data.startDate = moment(this.startToEndDate[0]).format("YYYY-MM-DD")
                data.endDate = moment(this.startToEndDate[1]).format("YYYY-MM-DD")
            }



            let newData = {}
            // 循环对象，判断对象中某些属性是否存在，且排除属性值为空格的属性
            for (let key in data) {
                if (data[key] && data[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
                    newData[key] = data[key]
                }
            }

            this.$nextTick(() => {
                this.$refs.rt.clearData();
            })
            let _this = this;
            this.SendToService({
                url: '/api/services/app/TPS_Invoice/GetPaged',
                method: 'get',
                params: newData,
                success(res) {
                    _this.data.splice(0, _this.data.length);
                    _this.totalItems = res.totalCount;
                    res.items.forEach(item => _this.data.push(item));
                    _this.data = res.items.map(item => {
                        return {
                            ...item
                        }
                    })
                }
            });
        },
        refreshGoFirstPage() {
            this.SearchDto = {
                name: '',
                projectName: '',
                status: '',
                category: '',
                invoiceType: '',
                invoiceMonth: '',
                startDate: '',
                endDate: ''
            };
            this.pageNumber = 1;
            this.request.SkipCount = 0;
            this.getData();
        },
        handleReject(data) {
            let _this = this;
            if (data.status == 'Processed' || data.status == 'Reject') {
                abp.message.warn('当前状态不能执行此操作');
                return false;
            }
            _this.SendToService({
                url: '/api/services/app/TPS_Invoice/Reject?id=' + data.id,
                method: 'post',
                success(res) {
                    abp.message.success('驳回成功');
                    _this.refreshGoFirstPage();
                }
            });
        },
        SendToService(opts) {
            this.spinning = true;
            let options = {
                url: this.$apiUrl + opts.url,
                method: opts.method,
                data: opts.data,
                params: opts.params,
                headers: { "Content-Type": "application/json-patch+json" },
            };
            this.$api.request(options).then((res) => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(res);
                }
                if (res.status == 200) {
                    if (opts.success && typeof opts.success == 'function') {
                        opts.success(res.data);
                    }
                } else {
                    abp.message.error(res.statusText);
                }
            }).catch(e => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(e);
                }
                console.error(e);
            });
        },
        /*
        分页相关
        */
        // 情况查询条件并重新查询
        clearFilterAndRefresh() {
            this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
            this.SearchDto.Name = "";
            this.SearchDto.FieldType = undefined;
            this.getData();
        },
        // 排序更新data
        updateSortData(newV) {
            let { columnKey, order } = newV;
            this.request.sorting = order ? `${columnKey} ${order}` : "";
            this.getData();
        },
        // table选择事件
        updateSelectChange(newV) {
            let { selectedRowKeys, selectedRows } = newV;
            this.onSelectChange(selectedRowKeys, selectedRows);
        },
        onSelectChange(keys, rows) {
            if (!!this.selectedRows) {
                this.selectedRows.splice(0, this.selectedRows.length);
                for (let i = 0; i < rows.length; i++) {
                    this.selectedRows.push(rows[i]);
                }
            }
        },
        // 分页
        updatePageChange(newV) {
            let { page, pageSize } = newV;
            this.onChange(page, pageSize);
        },
        onChange(page, pageSize) {
            this.pageNumber = page;
            this.request.SkipCount =
                (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        updateShowSizeChange(newV) {
            let { current, size } = newV;
            this.showSizeChange(current, size);
        },
        showSizeChange(current, size) {
            this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.getData();
        },
    }
};
</script>

<style lang="less" scoped>
.btn--container {
    margin-bottom: 20px;
}

.btn--footer {

    // border: 1px solid #e8e8e8;
    // margin: 20px;
    // padding: 20px;
    .table-delete {
        margin-left: 10px;
        margin-right: 10px;
    }

}

.btn--footer {
    margin-bottom: 20px;
}
</style>
