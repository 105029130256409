<template>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
        <a-form-item label="姓名">
            <a-input v-decorator="['name', { rules: [{}] }]" :disabled=true />
        </a-form-item>
        <a-form-item label="开票金额">
            <a-input-number :min="0" :step="0.01" v-decorator="['amount', { rules: [{ required: true }] }]" />
        </a-form-item>
        <a-form-item label="开票日期">
            <a-date-picker v-decorator="['taxDate', { rules: [] }]" placeholder="请选择开票日期" />
        </a-form-item>
        <a-form-item label="税票号">
            <a-input v-decorator="['taxNumber', { rules: [{}] }]" />
        </a-form-item>
        <a-form-item label="开票附件">
            <a-upload v-decorator="[
                'fileList',
                {
                    rules: [
                        { required: true, message: '请上传发票', type: 'array' },
                    ],
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                },]" :action="uploadUrl" :multiple="true" :data="{ subdir: this.subdirName }" :showUploadList="{
        showPreviewIcon: true
    }" @change="handleUploadChange">
                <a-button type="primary"> <a-icon type="upload" /> 上传</a-button>
            </a-upload>
        </a-form-item>
        <a-form-item label="财务备注">
            <a-textarea v-decorator="['approvalRemark', { rules: [{}] }]" placeholder="请输入内容" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" html-type="submit">
                {{ l("Save") }}
            </a-button>
        </a-form-item>
    </a-form>
</template>
  
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
export default {
    mixins: [ModalComponentBase],
    name: "create-or-edit-tpsInvoiceDetail",
    data() {
        return {
            // 获取到的数据
            entity: {},
            spinning: false,
            UserData: [],
            Enabled: false,
            // 上传文件
            fileList: [],
            uploadUrl: "",
            subdirName: "",
        };
    },
    components: {},
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "register" });
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
        this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
        this.subdirName = environment.production ? "abpProd" : "abpDev";
    },
    mounted() {
        this.form.setFieldsValue({
            name: this.name,
        });
        this.getData();
        //this.$refs.UserID.readOnly = true;
        //this.$refs.ProjectID.readOnly = true;
    },
    methods: {
        /**
         * 获取数据
         */
        getData() {
            var that = this;
            if (that.id && that.id != '') {
                that.SendToService({
                    url: '/api/services/app/TPS_InvoiceDetail/GetEditById',
                    method: 'get',
                    params: {
                        id: this.id,
                    },
                    success(res) {
                        that.entity = res;
                        if (res.invoiceurl != null && res.invoiceurl != '') {
                            that.fileList = [{ uid: res.id, name: '发票附件', url: AppConsts.uploadBaseUrl + res.invoiceurl }];
                        }
                        that.form.setFieldsValue({
                            amount: res.amount,
                            //invoiceurl: res.invoiceurl,
                            taxNumber: res.taxNumber,
                            taxDate: res.taxDate,
                            approvalRemark: res.approvalRemark,
                            fileList:that.fileList
                        });
                    }
                });
            }
        },
        // 上传组件 @change
        handleUploadChange(info) {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
                if (file.response) {
                    file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                }
                return file;
            });
        },
        normFile(e) {
            if (Array.isArray(e)) {
                return e;
            }
            if (e.fileList.length > 0) {//单文件上传
                return e && [e.fileList[e.fileList.length - 1]];
            }
            return e && e.fileList;
        },
        /**
         * 提交表单
         */
        handleSubmit(e) {
            var that = this;
            e.preventDefault();
            that.form.validateFieldsAndScroll((err, values) => {

                if (!err) {
                    var dto = {
                        amount: values.amount,
                        invoiceurl: values.fileList.length > 0 ? values.fileList[0].url.replace(AppConsts.uploadBaseUrl, '') : '',
                        taxNumber: values.taxNumber,
                        taxDate: values.taxDate,
                        approvalRemark: values.approvalRemark
                    }
                    that.SendToService({
                        url: '/api/services/app/TPS_InvoiceDetail/SaveTPS_InvoiceDetail',
                        method: 'post',
                        data: { ...that.entity, ...dto },
                        success(res) {
                            that.success(true);
                        }
                    })
                }
            });
        },
        SendToService(opts) {
            this.spinning = true;
            let options = {
                url: this.$apiUrl + opts.url,
                method: opts.method,
                data: opts.data,
                params: opts.params,
                headers: { "Content-Type": "application/json-patch+json" },
            };
            this.$api.request(options).then((res) => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(res);
                }
                if (res.status == 200) {
                    if (opts.success && typeof opts.success == 'function') {
                        opts.success(res.data);
                    }
                } else {
                    abp.message.error(res.statusText);
                }
            }).catch(e => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(e);
                }
                console.error(e);
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.btn--container .ant-form-item-children {
    display: block;
    text-align: center;
}

.pleaseSelect-text {
    font-size: 14px;
    padding: 0 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
}
</style>
  