<template>
    <a-spin :spinning="spinning">
        <ele-table ref="rt" :columns="columns" :table-data="data" :total-items="totalItems" :actions-type="actionsType"
            :is-full="true" :current-page="pageNumber" :hide-row-selection="true" @emitRefreshData="clearFilterAndRefresh"
            @emitSortData="updateSortData" @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange" @emitLinkClick="linkClick">
            <!-- 查询 -->
            <a-row :gutter="8">
                <a-col class="gutter-row" :span="4">
                    <a-input v-model.trim="SearchDto.nameOrPhone" placeholder="名称/手机号" allow-clear />
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-select v-model="SearchDto.status" style="width: 100%" show-search :filter-option="filterOption"
                        :options="statusOptions" placeholder="开票状态" allowClear>
                    </a-select>
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary" @click="getData()" v-if="isGranted('Pages.TPS_InvoiceDetail.Query')">
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
            </a-row>
        </ele-table>
    </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy, initFieldLibOptions } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import DetailModal from "./detailModal";
import ApprovalModal from "./approvalModal";

export default {
    mixins: [ModalComponentBase],
    name: "tpsInvoiceDetail-index",
    components: { EleTable },
    data() {
        return {
            spinning: false,
            selectedRowKeys: [],
            SearchDto: {
                nameOrPhone: '',
                status: ''
            },
            // 总记录数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 总页数
            totalPages: 1,
            // 
            pagerange: [1, 1],
            // 每页显示数
            pageSizeOptions: ["10", "20", "30", "40"],
            request: { sorting: "creationTime desc", maxResultCount: 50, skipCount: 0 },
            // 表头
            columns: [],
            actionsType: null,
            // 数据
            data: [],
            ComponentList: [],
            invoiceId: '',
            //状态类型
            statusOptions: [
                {
                    label: '待处理',
                    value: 1
                },
                {
                    label: '已处理',
                    value: 2
                },
                {
                    label: '已驳回',
                    value: 3
                },
            ],
        };
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
        if (this.id) {
            this.invoiceId = this.id
            this.getData();
            this.initActionsType();
            this.initColums();
        }

    },
    methods: {
        /**
* 搜索框
* @param input
* @param option
*/
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        initColums() {
            console.log(this.detailData)
            let _this = this;
            this.columns = [
                {
                    title: "姓名",
                    dataIndex: "name",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "name" },
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "phone" },
                },
                {
                    title: "发票类型",
                    dataIndex: "invoiceType",
                    sorter: false,
                    align: "center",
                    type: "custom",
                    scopedSlots: { customRender: "invoiceType" },
                    customRender: function (text, record, index) {
                        return text == "Corporate" ? '公对公' : '公对个体';
                    },
                },
                {
                    title: "开票金额",
                    dataIndex: "amount",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "amount" },

                },
                {
                    title: "发票",
                    dataIndex: "invoiceurl",
                    align: "center",
                    type: "link",
                    linkLabel: '查看',
                    scopedSlots: { customRender: "invoiceurl" },
                },
                {
                    title: "发票号",
                    dataIndex: "taxNumber",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "taxNumber" }
                },
                {
                    title: "客户备注",
                    dataIndex: "remark",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "remark" }
                },
                {
                    title: "财务备注",
                    dataIndex: "approvalRemark",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "approvalRemark" }
                },
                {
                    title: "状态",
                    dataIndex: "status",
                    sorter: false,
                    align: "center",
                    type: "custom",
                    scopedSlots: { customRender: "status" },
                    customRender: function (text, record, index) {
                        if (text == 'Pending') {
                            return "待处理";
                        }
                        else if (text == 'Processed') {
                            return "已处理";
                        } else {
                            return "已驳回";
                        }
                    },
                },
                {
                    title: "请求备注",
                    dataIndex: "remark",
                    sorter: false,
                    align: "center",
                    scopedSlots: { customRender: "remark" }
                }, {
                    title: this.l("Action"),
                    dataIndex: "actions",
                    fixed: "right",
                    width: 200,
                    scopedSlots: { customRender: "actions" },
                }
            ];
        },
        initActionsType() {
            let _this = this;
            let obj = {
                type: "tpsInvoice",
                isShow: true,
                fns: {
                    detailList: [
                        {
                            granted: true,
                            showFunc(record) {
                                return record.status != 'Reject' && _this.isReject;
                            },
                            name: '开票',
                            fn: (data) => {
                                ModalHelper.create(
                                    DetailModal,
                                    { id: data.id, name: data.name },
                                    {
                                        width: "500px",
                                    }
                                ).subscribe((res) => {
                                    if (res) {
                                        this.success(true);
                                        this.getData();
                                    }
                                });
                            },
                        }, {
                            granted: this.isGranted("Pages.TPS_Invoice.Reject"),
                            showFunc(record) {
                                return record.status == 'Pending' && _this.isReject;
                            },
                            name: '驳回',
                            //icon: "profile",
                            fn: (data) => {
                                ModalHelper.create(
                                    ApprovalModal,
                                    { id: data.id, type: 2 },
                                    {
                                        width: "500px",
                                    }
                                ).subscribe((res) => {
                                    if (res) {
                                        this.success(true);
                                        this.getData();
                                    }
                                });

                            },
                        }
                    ],
                    isShow: true,
                },
            };
            this.actionsType = obj;
        },
        getData() {
            let data = {
                ...{
                    invoiceId: this.invoiceId,
                    sorting: this.request.sorting,
                    skipCount: this.request.skipCount,
                    maxResultCount: this.request.maxResultCount
                }, ...this.SearchDto
            };
            let newData = {}
            // 循环对象，判断对象中某些属性是否存在，且排除属性值为空格的属性
            for (let key in data) {
                if (data[key] && data[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
                    newData[key] = data[key]
                }
            }
            this.$nextTick(() => {
                this.$refs.rt.clearData();
            })
            let _this = this;

            this.SendToService({
                url: '/api/services/app/TPS_InvoiceDetail/GetPaged',
                method: 'get',
                params: newData,
                success(res) {
                    _this.data.splice(0, _this.data.length);
                    _this.totalItems = res.totalCount;
                    res.items.forEach(item => _this.data.push(item));
                    _this.data = res.items.map(item => {
                        return {
                            ...item
                        }
                    })
                }
            });
        },
        linkClick(newV) {
            const { item, index } = newV;
            window.open(AppConsts.uploadBaseUrl + item.invoiceurl)
        },
        refreshGoFirstPage() {
            this.SearchDto = {
                nameOrPhone: '',
                status: ''
            }
            this.pageNumber = 1;
            this.request.SkipCount = 0;
            this.getData();
        },
        handleReject(data) {
            let _this = this;
            if (data.status == 'Processed' || data.status == 'Reject') {
                abp.message.warn('当前状态不能执行此操作');
                return false;
            }
            _this.SendToService({
                url: '/api/services/app/TPS_InvoiceDetail/Reject?id=' + data.id,
                method: 'post',
                success(res) {
                    abp.message.success('驳回成功');
                    _this.refreshGoFirstPage();
                }
            });
        },
        SendToService(opts) {
            this.spinning = true;
            let options = {
                url: this.$apiUrl + opts.url,
                method: opts.method,
                data: opts.data,
                params: opts.params,
                headers: { "Content-Type": "application/json-patch+json" },
            };
            this.$api.request(options).then((res) => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(res);
                }
                if (res.status == 200) {
                    if (opts.success && typeof opts.success == 'function') {
                        opts.success(res.data);
                    }
                } else {
                    abp.message.error(res.statusText);
                }
            }).catch(e => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(e);
                }
                console.error(e);
            });
        },
        /*
        分页相关
        */
        // 情况查询条件并重新查询
        clearFilterAndRefresh() {
            this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
            this.SearchDto.Name = "";
            this.SearchDto.FieldType = undefined;
            this.getData();
        },
        // 排序更新data
        updateSortData(newV) {
            let { columnKey, order } = newV;
            this.request.sorting = order ? `${columnKey} ${order}` : "";
            this.getData();
        },
        // table选择事件
        updateSelectChange(newV) {
            let { selectedRowKeys, selectedRows } = newV;
            this.onSelectChange(selectedRowKeys, selectedRows);
        },
        onSelectChange(keys, rows) {
            if (!!this.selectedRows) {
                this.selectedRows.splice(0, this.selectedRows.length);
                for (let i = 0; i < rows.length; i++) {
                    this.selectedRows.push(rows[i]);
                }
            }
        },
        // 分页
        updatePageChange(newV) {
            let { page, pageSize } = newV;
            this.onChange(page, pageSize);
        },
        onChange(page, pageSize) {
            this.pageNumber = page;
            this.request.SkipCount =
                (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        updateShowSizeChange(newV) {
            let { current, size } = newV;
            this.showSizeChange(current, size);
        },
        showSizeChange(current, size) {
            this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.getData();
        }
    }
};
</script>

<style lang="less" scoped>
.btn--container {
    margin-bottom: 20px;
}

.btn--footer {

    // border: 1px solid #e8e8e8;
    // margin: 20px;
    // padding: 20px;
    .table-delete {
        margin-left: 10px;
        margin-right: 10px;
    }

}

.btn--footer {
    margin-bottom: 20px;
}
</style>
