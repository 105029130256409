var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', { rules: [{}] }]),expression:"['name', { rules: [{}] }]"}],attrs:{"disabled":true}})],1),_c('a-form-item',{attrs:{"label":"开票金额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['amount', { rules: [{ required: true }] }]),expression:"['amount', { rules: [{ required: true }] }]"}],attrs:{"min":0,"step":0.01}})],1),_c('a-form-item',{attrs:{"label":"开票日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['taxDate', { rules: [] }]),expression:"['taxDate', { rules: [] }]"}],attrs:{"placeholder":"请选择开票日期"}})],1),_c('a-form-item',{attrs:{"label":"税票号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['taxNumber', { rules: [{}] }]),expression:"['taxNumber', { rules: [{}] }]"}]})],1),_c('a-form-item',{attrs:{"label":"开票附件"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fileList',
            {
                rules: [
                    { required: true, message: '请上传发票', type: 'array' } ],
                valuePropName: 'fileList',
                getValueFromEvent: _vm.normFile,
            } ]),expression:"[\n            'fileList',\n            {\n                rules: [\n                    { required: true, message: '请上传发票', type: 'array' },\n                ],\n                valuePropName: 'fileList',\n                getValueFromEvent: normFile,\n            },]"}],attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"showUploadList":{
    showPreviewIcon: true
}},on:{"change":_vm.handleUploadChange}},[_c('a-button',{attrs:{"type":"primary"}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传")],1)],1)],1),_c('a-form-item',{attrs:{"label":"财务备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['approvalRemark', { rules: [{}] }]),expression:"['approvalRemark', { rules: [{}] }]"}],attrs:{"placeholder":"请输入内容"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }