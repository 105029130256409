<template>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
        <a-form-item label="驳回原因">
            <a-textarea v-decorator="['approvalRemark', { rules: [{ required: true }] }]" placeholder="请输入驳回原因" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" html-type="submit">
                {{ l("Save") }}
            </a-button>
        </a-form-item>
    </a-form>
</template>
  
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { AppConsts } from "@/abpPro/AppConsts";
export default {
    mixins: [ModalComponentBase],
    name: "tpsInvoice-approvalModal",
    data() {
        return {

        };
    },
    components: {},
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "register" });
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
    },
    mounted() {
        console.log(this.id)
    },
    methods: {
        /**
         * 提交表单
         */
        handleSubmit(e) {
            var that = this;
            e.preventDefault();
            var apiUrl='/api/services/app/TPS_Invoice/Reject' //主表驳回API
            if(this.type&&this.type==2){
                apiUrl='/api/services/app/TPS_InvoiceDetail/Reject' //明细表驳回API
            }
            that.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    var dto = {
                        id: that.id,
                        approvalRemark: values.approvalRemark
                    }
                    that.SendToService({
                        url: apiUrl,
                        method: 'post',
                        data: dto,
                        success(res) {
                            that.success(true);
                        }
                    })
                }
            });
        },
        SendToService(opts) {
            this.spinning = true;
            let options = {
                url: this.$apiUrl + opts.url,
                method: opts.method,
                data: opts.data,
                params: opts.params,
                headers: { "Content-Type": "application/json-patch+json" },
            };
            this.$api.request(options).then((res) => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(res);
                }
                if (res.status == 200) {
                    if (opts.success && typeof opts.success == 'function') {
                        opts.success(res.data);
                    }
                } else {
                    abp.message.error(res.statusText);
                }
            }).catch(e => {
                this.spinning = false;
                if (opts.complete && typeof opts.complete == 'function') {
                    opts.complete(e);
                }
                console.error(e);
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.btn--container .ant-form-item-children {
    display: block;
    text-align: center;
}

.pleaseSelect-text {
    font-size: 14px;
    padding: 0 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
}
</style>
  